.header h2 {
    @apply my-0 text-3.5xl lg:text-4xl leading-10 lg:leading-13;
}

.blog-posts-carousel-wrapper :global .glider {
    @apply -mx-5 sm:-mx-6 lg:-mx-15;
}

.blog-posts-carousel-wrapper :global .glider-track {
    @apply px-5 sm:px-6 lg:px-15 box-content;
}
