.hero-section {
    height: 440px;
    min-height: 100vw;
}

.hero-section--transparent-header {
    padding-top: calc(var(--pageHeaderHeight) + var(--bannerHeight));
}

.hero-section--tall {
    height: 520px;
}

.floating-cta-container--with-high-shadow {
    box-shadow: 0 -10px 4.5px rgb(0 0 0 / 2%), 0 -10px 15px rgb(0 0 0 / 4%), 0 -10px 67px rgb(0 0 0 / 6%);
}

@media (min-width: theme(screens.md)) {
    .hero-section {
        min-height: 440px;
    }
    /* max-width of page/container is 768px */
    .floating-cta-container {
        /* container width */
        width: theme(screens.md);
    }
}

@media (min-width: theme(screens.lg)) {
    .hero-section {
        height: calc(100vh - var(--pageHeaderHeight) - var(--bannerHeight));
        max-height: var(--heroMaxHeight);
    }

    .hero-section--with-icons-section {
        height: calc(100vh - var(--iconsBannerHeight) - var(--bannerHeight));
    }

    .hero-content {
        max-width: 700px;
    }

    .floating-cta-container {
        width: auto;
    }

    .floating-cta-container--with-high-shadow {
        @apply shadow-none;
    }
}
