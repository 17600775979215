.aspect-ratio-element {
    aspect-ratio: 3/2;
}

@supports not (aspect-ratio: 1 / 1) {
    .aspect-ratio-container {
        /* 3/2 aspect ratio */
        @apply relative pt-[66.66%];
    }

    .aspect-ratio-element {
        @apply absolute top-0 right-0 bottom-0 left-0;
    }
}

.card-image {
    max-height: 355px;
}

@media (min-width: theme(screens.lg)) {
    .card-image {
        max-height: none;
    }
}
